export default () => {
    const quotes = document.querySelectorAll('.js-quote');

    if (quotes.length < 1) {
        return;
    }

    function onIntersection(entries) {
        entries.forEach((entry) => {
            entry.target.classList.toggle('hide', !entry.isIntersecting);
        });
    }

    const observer = new IntersectionObserver(onIntersection, {
        root: null,
        threshold: 0.5,
    });

    quotes.forEach((item) => {
        observer.observe(item);
    });
};
