export default () => {
    const typewriterSpan = document.querySelector('.js-typewriter');
    const texts = [
        'Suchtechnologie.',
        'Suche für Marken.',
        'Suche für Verlage.',
        'Website-Suche.',
        'Such-API.',
        'Suchergebnisse.',
        'Enterprise Suche.',
    ];
    const speed = 150;
    const offset = 1000;
    const interval = 6000;
    let currentTextIndex = 0;
    let ready = true;

    function typeWriter(text, pos = 0) {
        if (pos < text.length) {
            ready = false;
            typewriterSpan.innerHTML += text.charAt(pos);
            setTimeout(() => {
                typeWriter(text, pos + 1);
            }, speed);
        } else {
            ready = true;
        }
    }

    typewriterSpan.innerHTML = '';
    setTimeout(() => {
        typeWriter(texts[currentTextIndex]);
        currentTextIndex += 1;
    }, offset);

    setInterval(() => {
        if (ready) {
            typewriterSpan.innerHTML = '';
            typeWriter(texts[currentTextIndex]);
            if (currentTextIndex < (texts.length - 1)) {
                currentTextIndex += 1;
            } else {
                currentTextIndex = 0;
            }
        }
    }, interval);
};
