export default () => {
    const playVideoButtons = document.querySelectorAll('.js-play-video-button');

    playVideoButtons.forEach((button) => {
        button.addEventListener('click', (event) => {
            const video = document.querySelector(event.target.dataset.target);

            if (video.paused) {
                video.play();
                video.setAttribute('controls', 'true');
                button.classList.remove('is-visible');
            }
        });
    });
};
